<template>
  <div>
    <b-row>
      <b-col sm="12"
        ><InputText
          title="Nome"
          field="name"
          :formName="formName"
          :required="true"
          :maxLength="40"
          v-model="manufacturer.name"
          :markFormDirty="false"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button
            _key="btnSaveProductManufacturer"
            type="success"
            title="Salvar"
            :disabled="!isFormValid(formName)"
            classIcon="fas fa-save"
            size="medium"
            :clicked="saveProductManufacturer"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Manufacturer from "@/components/modules/stock/manufacturer/Manufacturer.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ManufacturerCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText },
  data() {
    return {
      formName: "manufacturerCreateUpdate",
      manufacturer: new Manufacturer(),
      urlCreate: "/api/v1/stock/manufacturer/create",
      urlUpdate: "/api/v1/stock/manufacturer/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveProductManufacturer() {
      if (this.manufacturer.id) {
        let params = { url: this.urlUpdate, obj: this.manufacturer };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSaveProductManufacturer"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.manufacturer };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
           this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSaveProductManufacturer"]);
        });
      }
    },
    update(data) {
      this.manufacturer = new Manufacturer();
      this.manufacturer.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "productManufacturerCreate") {
          this.resetValidation(this.formName);
          this.manufacturer = new Manufacturer();
        }
        if (event.name == "productManufacturerUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
