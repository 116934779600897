<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="btnProductManufacturerCreate"
          title="Fabricante"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="create"
        />
      </div>
    </ViewTemplateConfiguration>
    <Modal
      title="Fabricante"
      :width="500"
      :height="250"
      v-show="showModal('productManufacturerCreateUpdate')"
    >
      <ManufacturerCreateUpdate />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import ManufacturerCreateUpdate from '../../../components/modules/stock/manufacturer/ManufacturerCreateUpdate.vue'
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ProductManufacturerView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    ManufacturerCreateUpdate
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/stock/manufacturer/get-all",
        urlDeleteAllApi: "/api/v1/stock/manufacturer/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            eventName: "productManufacturerUpdate",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent","openModal", "removeLoading"]),
    create() {
      this.openModal("productManufacturerCreateUpdate");
      this.addEvent({ name: "productManufacturerCreate" });
      this.removeLoading(["btnProductManufacturerCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "productManufacturerUpdate")
          this.openModal("productManufacturerCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
