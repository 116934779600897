<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnProductCategoryCreate" title="Categoria" classIcon="fas fa-plus-circle" type="primary"
          size="small" :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Categoria" :width="500" :height="250" v-show="showModal('categoryProductCreateUpdate')">
      <CategoryProductCreateUpdate />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import CategoryProductCreateUpdate from "../../../components/modules/stock/category-product/CategoryProductCreateUpdate.vue";

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ProductCategoryView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    CategoryProductCreateUpdate,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/stock/category-product/get-all",
        urlDeleteAllApi: "/api/v1/stock/category-product/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            eventName: "productCategoryUpdate",
          },
          {
            field: "colorHtml",
            type: "html",
            styleBody: "width: 200px;",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("categoryProductCreateUpdate");
      this.addEvent({ name: "productCategoryCreate" });
      this.removeLoading(["btnProductCategoryCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "productCategoryUpdate")
          this.openModal("categoryProductCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
