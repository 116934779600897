export default class CategoryProduct {
    constructor() {
        this.id = "";
        this.name = "";
        this.color = "";
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.color = data.color;
    }
}