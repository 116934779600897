<template>
  <div>
    <Panel
      :module="panel.module"
      :title="panel.title"
      :showFilter="panel.showFilter"
      :showSearch="panel.showSearch"
      :showButtons="panel.showButtons"
    >
      <div slot="content-main">
        <div>
          <br />
          <b-tabs v-model="tabIndex">
            <b-tab title="Categoria">
              <CategoryProduct v-if="tabIndex == 0" />
            </b-tab>
            <b-tab title="Fabricante">
              <Manufacturer v-if="tabIndex == 1" />
            </b-tab>
            <b-tab title="Tipo">
              <TypeProduct v-if="tabIndex == 2" />
            </b-tab>
            <b-tab title="Unidade de Medida">
              <UnitOfMeasurement v-if="tabIndex == 3" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import CategoryProduct from "./CategoryProduct.vue";
import Manufacturer from "./Manufacturer.vue";
import TypeProduct from "./TypeProduct.vue";
import UnitOfMeasurement from "./UnitOfMeasurement.vue";

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";

import { mapMutations } from "vuex";

export default {
  name: "ConfiguracaoStockView",
  components: {
    Panel,
    CategoryProduct,
    Manufacturer,
    TypeProduct,
    UnitOfMeasurement,
  },
  data() {
    return {
      panel: {
        module: "Estoque",
        title: "Configuração",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
      tabIndex: 0,
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>
